import loading from "../../../img/loading.svg";
import "./style.scss";
import logo from "../../../img/icon.svg";
import LoadingMessage from "../loading_message/Loading";
import { useEffect } from "react";
import UserIcon from "../../../img/user.png"


function Loading(props) {

  const { question } = props; 

  return (
    <div className="Chat">
            <div className="qs">
              {/* <div className="acc">user</div> */}
              <div className="txt">{question}</div>
              <div className="icon">
                <img src={UserIcon} width="30" height="30" alt="Logo" />
              </div>
            </div>
       

            <div className="res">
                  <div className="icon">
                    <img src={logo} width="100" height="100" alt="Logo" />
                  </div>
      
                  <div className="txt">
                    <LoadingMessage></LoadingMessage>
                  </div>
            </div>

        </div>

  );
}

export default Loading;