import loading from "../../../img/loading.svg";
import "./style.scss";

function Loading() {
  return (
    <div className="loader_container_submit_button">
      <img className="loader_submit_button" src={loading} alt="Loading" />
    </div>
  );
}

export default Loading;