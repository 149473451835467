import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

const CustomSubstring = ({ content, delay }) => {
  const [substring, setSubstring] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentLength = substring.length;
      const nextChar = content.charAt(currentLength);
      setSubstring(substring + nextChar);

      if (currentLength === content.length - 1) {
        clearInterval(intervalId);
      }
    }, delay);

    return () => clearInterval(intervalId);
  }, [content, delay, substring]);

  return <ReactMarkdown >{substring}</ReactMarkdown>;
};

export default CustomSubstring;