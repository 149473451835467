import React, { Fragment, useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./style.scss";
import axios from 'axios';
import PlusIcon from "../../../assets/PlusIcon";
import TrashIcon from "../../../assets/TrashIcon"; 
import ChatIcon from "../../../assets/ChatIcon";
import CloseIcon from "../../../assets/CloseIcon";
import TreeLineIcon from "../../../assets/TreeLineIcon";
import logo from "../../../img/logo.png";
import LogoutIcon from "../../../assets/LogoutIcon";

const Menu = (props) => {

  const navigate = useNavigate();
  const [chatSessions, setChatSessions] = useState([]);
  const location = useLocation();
  const menuRef = useRef(null);
  const btnRef = useRef(null);
  const {chatSessionTitle, token, account_identifier} = props;

  const checkTrashActivade = (chat_session_identifier) => {
    const url = location.pathname.split('/');
    if (url[1] == "chat" && url[2] == chat_session_identifier) {
      return true
    }
    return false;
  };

  useEffect(() => {

    const fetchChatSessions = async (retryCount = 3) => {
      try {
        const response = await axios.post(process.env.REACT_APP_API_URL + '/account_chat_sessions', {
          account_identifier: account_identifier
        },{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        
        if (response && response.data) {
          setChatSessions(response.data.chat_sessions);
        } else {
          return fetchChatSessions(retryCount - 1);
        }

      } catch (error) {

        if (error.response.status === 502 && retryCount > 0) {
          console.log('Erro 502');
          return fetchChatSessions(retryCount - 1);
        }
        console.error('Erro na requisição:', error.response.data.detail || error.response.data);
        
      }     
    };

    fetchChatSessions();
  },[]);

  const deleteSession = async (chat_session_identifier) => {

    const chatSessionIdentifiers = [chat_session_identifier];
  
    try {
      const response = await axios.delete(
         process.env.REACT_APP_API_URL+'/delete_sessions', {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          data: {
            account_identifier: account_identifier,
            chat_sessions_identifiers: chatSessionIdentifiers
          }
      });
  
  
      setChatSessions(response.data.chat_sessions);
      navigate("/", { state: {token, account_identifier }})
    } catch (error) {
      console.error('Erro na requisição:', error);
    }
     
  }


  const deleteSessions = async () => {
    console.log(token);
    
    const chatSessionIdentifiers = chatSessions? chatSessions.map(session => session.chat_session_identifier) : [];
    
    try {
      const response = await axios.delete(process.env.REACT_APP_API_URL + '/delete_sessions', {

        headers: {
          'Authorization': `Bearer ${token}`
        }
        ,
        data: {
          account_identifier: account_identifier,
          chat_sessions_identifiers: chatSessionIdentifiers
        }
      });


      setChatSessions(response.data.chat_sessions);
      navigate("/")
    } catch (error) {
      console.error('Erro na requisição:', error);
    }
    
  }

  const showMenuMd = () => {
    menuRef.current.classList.add("showMd");
    document.body.style.overflowY = "hidden";
  };

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (
        !menuRef?.current?.contains(e.target) &&
        !btnRef?.current?.contains(e.target)
      ) {
        menuRef?.current?.classList?.remove("showMd");
        document.body.style.overflowY = "auto";
      }
    });

    window.addEventListener("resize", () => {
      if (!window.matchMedia("(max-width:767px)").matches) {
        document.body.style.overflowY = "auto";
      } else {
        if (menuRef?.current?.classList?.contains("showMd")) {
          document.body.style.overflowY = "hidden";
        } else {
          document.body.style.overflowY = "auto";
        }
      }
    });
  });

  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('accountIdentifier');

    navigate('/login');
  };
  

  return (
    <Fragment>
     
     <header>
        <div className="start">
          <button onClick={showMenuMd} ref={btnRef}>
            <TreeLineIcon/>
          </button>
        </div>

        <div className="title">
            {chatSessionTitle}
        </div>

        <div className="end">
          <button
          onClick={() => {
            navigate('/');
            }}
          >
            <PlusIcon />
            
          </button>
        </div>
      </header>


      <div className="menu" ref={menuRef}>

        <div>
          <button
            type="button"
            aria-label="new"
            onClick={() => {
            navigate('/');
            }}
          >
            <PlusIcon />
            Novo chat
          </button>
          
        </div>

        <button 
          className="logout-button"
          title="Sair"
          type="button"
          onClick={() => {
            logout();
            }}
        >
            <LogoutIcon />
        </button>

        <div className="history">
            {chatSessions?.length > 0 && chatSessions?.map((chatSession, index) => {
                return (
                <div key={`chatSession_${index}`} className="chatButton">  
                  <button
                      className="textHistory tooltip"
                      onClick={() => {
                      navigate(`/chat/${chatSession.chat_session_identifier}`);
                      }}
                  >
                      <ChatIcon />

                      {chatSession.title}
                      <span class="tooltiptext">{chatSession.title}</span>

                  </button>

                  <button className="trash" onClick={ () => { deleteSession(chatSession.chat_session_identifier)}} style={{visibility: checkTrashActivade(chatSession.chat_session_identifier) ? 'visible' : 'hidden' }} >
                      <TrashIcon />
                  </button>
                </div>
                );
            })}
        </div>

        <div className="actions">

          <button onClick={deleteSessions}>
                <TrashIcon />
            Limpar histórico
          </button>

        </div>
      </div>

      
      <div className="exitMenu">
        <button>
          <CloseIcon />
        </button>
      </div>

    </Fragment>
  );
};

export default Menu;
