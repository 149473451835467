import loading from "../../../img/loading.svg";
import "./style.scss";

function Loading() {
  return (
    <div className="loader_container">
      <img className="loader" src={loading} alt="Loading" />
      <div data-for="text">
        Carregando mensagens do Chat...
      </div>
    </div>
  );
}

export default Loading;