import loading from "../../../img/loading_message.svg";
import "./style.scss";

function Loading() {
  return (
    <div className="loader_container_message">
      <img className="loader_message" src={loading} alt="Loading" />
    </div>
  );
}

export default Loading;