// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_container_submit_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  flex-direction: column;
}

.loader_submit_button {
  width: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/layout/loading_submit_button/style.scss"],"names":[],"mappings":"AAAA;EAGI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AADJ;;AAIA;EACI,WAAA;AADJ","sourcesContent":[".loader_container_submit_button {\n    // width: 100%;\n    // height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: transparent;\n    flex-direction: column;\n}\n\n.loader_submit_button {\n    width: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
