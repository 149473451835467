import loading from "../../../img/loading_input.svg";
import "./style.scss";

function Loading() {
  return (
    <div className="loader_container_input">
      <img className="loader_input" src={loading} alt="Loading" />
    </div>
  );
}

export default Loading;