import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.scss";
import logo from "../../../../img/icon.svg";
import axios from 'axios';
import LoadingSubmit from "../../../layout/loading_submit_button/Loading";



const Login = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const [formData, setFormData] = useState({
      account_email: '',
      account_password: ''
    });

  const formHandle = async (e) => {
    setIsDisabled(true);
    e?.preventDefault();    
  
    
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL+'/login', {
          account_email: formData.account_email,
          account_password: formData.account_password
      }, {
        auth: {
          username:   process.env.REACT_APP_API_USER,
          password:   process.env.REACT_APP_API_PASSWORD,
        },
      });

      // Armazena o token no Local Storage
      localStorage.setItem('authToken', response.data.token);
      localStorage.setItem('accountIdentifier', response.data.account_identifier);
    
      navigate(`/`);
      
    } catch (error) {
      setIsDisabled(false);
      setError(true)
      console.error('Erro na requisição: Envio de question' , error);
    }


  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
        ...prevState,
        [name]: value
    }));
}

  return (
    <div className="Contain">
      <div className="icon">
        <img src={logo} width="140" height="80" alt="Logo" />
        <div className="logoTitulo">
          <h1 className="title currentColor login-title">Gepeto Chat</h1>
        </div>
      </div>

      <div> <h4>Entrar</h4> </div>

        {
          error &&
          <div className="error">
            Login/Senha incorreto. Caso não tenha criado a conta, pode criá-la através do <Link to="/signup">link</Link> 
          </div> 
        }
        <form className="Form" onSubmit={formHandle}>
          <div>
            <div className="email">
    
              <label htmlFor="">
                    <p>E-mail:</p>
                    <input type="text" id="account_email" name="account_email" value={formData.account_email} onChange={handleChange} disabled={isDisabled}/>
                </label>

            </div>

            <div className="password">

            <label htmlFor="">
                    <p>Senha:</p>
                    <input type="password" id="account_password" name="account_password" value={formData.account_password} onChange={handleChange} disabled={isDisabled}/>
                </label>
            </div>


            <div className="btns">

            {isDisabled ? 

              <button type="submit" className="submit_disabled" disabled={isDisabled}>
                <span className="submit_text">Entrar</span>
                <LoadingSubmit className="loading_icon"/>
              </button>

                :
                
              <button type="submit">Entrar</button>

            }

            </div>
          </div>

          <div data-for="acc-sign-up-login">
            <Link className="link-right" to={isDisabled ? "#" : "/signup"} >
                Clique aqui para criar a conta
            </Link>
          </div>
          
        </form>
      
    </div>
  );
};

export default Login;
