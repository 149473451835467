import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./style.scss";
import logo from "../../../../img/icon.svg"
import axios from 'axios';
import LoadingSubmit from "../../../layout/loading_submit_button/Loading";



const Signup = () => {
    const navigate = useNavigate();
    const [isDisabled, setIsDisabled] = useState(false);


    const [formData, setFormData] = useState({
      account_identifier: '',
      account_name: '',
      account_email: '',
      account_password: ''
    });
    
  const formHandle = async (e) => {
    e?.preventDefault();
    setIsDisabled(true);
    
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL+'/signin', {
          account_identifier: formData.account_identifier,
          account_name: formData.account_name,
          account_email: formData.account_email,
          account_password: formData.account_password
      }, {
        auth: {
          username:   process.env.REACT_APP_API_USER,
          password:   process.env.REACT_APP_API_PASSWORD,
        },
      });
      
    } catch (error) {
      console.error('Erro na requisição: Envio de question' , error);
    }

    setIsDisabled(false);
    navigate(`/login`);

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
        ...prevState,
        [name]: value
    }));
}

  return (
    <div className="Contain">
      <div className="icon">
        <img src={logo} width="140" height="80" alt="Logo" />
        <div className="logoTitulo">
          <h1 className="title currentColor login-title">Gepeto Chat</h1>
        </div>
      </div>

      <div> <h4>Criar Conta</h4></div>

        <form className="Form" onSubmit={formHandle}>
          <div>

            <div className="identify">
            
              <label htmlFor="">
                <p>Identificador da Conta:</p>
                <input type="text"  id="account_identifier" name="account_identifier" value={formData.account_identifier} onChange={handleChange} disabled={isDisabled}/>
              </label>

            </div>

            <div className="name">
    
              <label htmlFor="">
                  <p>Nome:</p>
                  <input type="text"  id="account_name" name="account_name" value={formData.account_name} onChange={handleChange} disabled={isDisabled}/>
              </label>

            </div>

            <div className="email">
    
              <label htmlFor="">
                    <p>E-mail:</p>
                    <input type="text"  id="account_email" name="account_email" value={formData.account_email} onChange={handleChange} disabled={isDisabled}/>
                </label>

            </div>

            <div className="password">

            <label htmlFor="">
                    <p>Senha:</p>
                    <input type="password"  id="account_password" name="account_password" value={formData.account_password} onChange={handleChange} disabled={isDisabled}/>
                </label>
            </div>

            <div className="btns">

              {isDisabled ? 

              <button type="submit" className="submit_disabled" disabled={isDisabled}>
                <span className="submit_text">Registrar</span>
                <LoadingSubmit className="loading_icon"/>
              </button>

                :
                
              <button type="submit">Registrar</button>

              }
      
            </div>
          </div>
        
        </form>
      
    </div>
  );
};

export default Signup;
