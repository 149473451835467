// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
}
body::-webkit-scrollbar {
  width: 8px;
}
body::-webkit-scrollbar-track {
  background: transparent;
}

.main-grid {
  display: grid;
  grid-template-columns: 260px auto;
}
@media screen and (max-width: 767px) {
  .main-grid {
    grid-template-columns: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;AADF;;AAIA;EACE,SAAA;EACA,UAAA;AADF;;AAIA;EACE,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,mEAAA;AADF;AAGE;EACE,UAAA;AADJ;AAIE;EACE,uBAAA;AAFJ;;AAOA;EACE,aAAA;EACA,iCAAA;AAJF;AAME;EAJF;IAKI,2BAAA;EAHF;AACF","sourcesContent":["$ColorBlack : #343541;\r\n\r\nhtml {\r\n  scroll-behavior: smooth;\r\n}\r\n\r\n* {\r\n  margin: 0;\r\n  padding: 0;\r\n}\r\n\r\nbody {\r\n  height: 100%;\r\n  overflow-y: auto;\r\n  overflow-x: hidden;\r\n  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;\r\n\r\n  &::-webkit-scrollbar {\r\n    width: 8px;\r\n  }\r\n\r\n  &::-webkit-scrollbar-track {\r\n    background: transparent;\r\n  }\r\n\r\n}\r\n\r\n.main-grid {\r\n  display: grid;\r\n  grid-template-columns: 260px auto;\r\n\r\n  @media screen and (max-width:767px) {\r\n    grid-template-columns: 100%;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
