import Message from '../Message/Message';
import axios from 'axios';
import Menu from '../Menu/Menu';
import "./style.scss";
import React, { useState, useEffect, useRef } from "react";
import {useParams, useLocation, useNavigate } from "react-router-dom";
import LoadingInput from "../../layout/loading_input/Loading";
import SendIcon from '../../../assets/SendIcon';
import LoadingMainWin from "../../layout/loading_chat/Loading";

function Chat() { 

  const { id = null } = useParams();
  const [prompt, setPrompt] = useState(''); 
  const [loadingChat, setLoadingChat] = useState(true);
  const [qaList, setQaList] = useState([]);
  const bottomRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [chatSessionTitle, setChatSessionTitle] = useState('');
  const [suggestion, setSuggestion] = useState(location.state?.lista || []);
  const [error, setError] = useState(false);
  const [suggestionVisible, setSuggestionVisible] = useState(true);
  const [textEffect, setTextEffect] = useState(location.state?.textEffect || false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [userQuestion, setUserQuestion] = useState(``);
  // Dados em cache
  const [token, setToken] = useState(localStorage.getItem('authToken'));
  const [account_identifier, setAccount_identifier] = useState(localStorage.getItem('accountIdentifier'));
  const [loadingMain, setLoadingMain] = useState(true);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    
  }, [qaList]);

  

 // Recuperação de CHAT
    useEffect(() => {

      const history = async () => {
        
        try {
            const response = await axios.get(
               process.env.REACT_APP_API_URL+`/messages/${account_identifier}/${id}`,

            {
              headers: {
                'ngrok-skip-browser-warning': 'true',
                'Authorization': `Bearer ${token}`
              }
    
            }
          );

           setQaList(response.data.messages);
           setChatSessionTitle(response.data.messages[0].content);
        } catch (error) {
           console.error('Erro na requisição: Recuperar Chat', error);
           navigate("/");
        }
      }

      history();
      setSuggestion(location.state?.lista || []);
      setTextEffect(location.state?.textEffect || false); 
      setLoadingMain(false);
      setLoadingChat(false);
      setIsDisabled(false);

    },[id]);


  const addList = async (question) => {
    setError(false)
    
    // answer
    try {
      // Temporariamente fixo
      const response = await axios.post(
        process.env.REACT_APP_API_URL+'/buzz_answer', {
          account_identifier: account_identifier,

        chat_session_identifier: id,
        question: question
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

    const lista1 = [
      {
        "content": question,
        "role": "user"
      },
      {
        "content": response.data.answer,
        "role": "assistant"
      }
    ];
   
    setSuggestion(response.data.next_questions);
    setQaList([...qaList, ...lista1]);

    } catch (error) {
      setLoadingChat(false);
      setError(true)
      setPrompt("");
      console.error('Erro na requisição: Envio de question' , error);
    }
  };

  const selectionSuggestion = (question) =>{
    setPrompt(question);
    sendQuestion(question)
  };

  const sendQuestion = async (question) => {
    setIsDisabled(true);
    setPrompt("");
    setTextEffect(false);
    setSuggestionVisible(false);
    setLoadingChat(true);
    setUserQuestion(question)
    await addList(question);
    setLoadingChat(false);
    setSuggestionVisible(true);
    setTextEffect(true);
    setIsDisabled(false);
  }


  return (
    <div className="chat-container">
      <div className="menu-column">
        <Menu chatSessionTitle={chatSessionTitle} token={token} account_identifier={account_identifier}/>
      </div>

      <div className="content-column">
        <div className="main">
          <div className="contentArea">

            {loadingMain ? 


              <LoadingMainWin/>

            :
              <>
              {loadingChat ? 

                <Message qaList={qaList} chat_session_identifier={id} textEffect={textEffect} loading={[true, userQuestion]} token={token} account_identifier={account_identifier} />

              :
                <Message qaList={qaList} chat_session_identifier={id} textEffect={textEffect} loading={[false, ""]} token={token} account_identifier={account_identifier}/>

              }
              </>
            }
            

            <div ref={bottomRef} />

          </div>

          <div className="inputArea">

            {
              error &&
                <div className="error">
                  Algo deu errado, por favor, tente novamente.
                </div> 
            }

            <div className='suggestion'>
              { suggestionVisible && suggestion.map((question, index) => (
                
                <div key={`suggestion_${index}`} className="card card-bg hover"
                    onClick={() => {
                        selectionSuggestion(question);
                    }}>
                    <p className="currentColor">
                    {question}
                    </p>
                  </div>

              ))}
            </div>

            <div className="flexBody">
                <div className="box">
                  <textarea
                    placeholder="Envie sua mensagem..."
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)} 
                    disabled={isDisabled}

                  />

                  {loadingChat ? 

                  <button disabled={loadingChat}> 
                    <LoadingInput/>   
                  </button>
                  
                  :

                  <button onClick={() =>{sendQuestion(prompt)}}>                
                    <SendIcon />
                  </button>
                    
                  }  
                </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Chat;
