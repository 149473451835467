import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Chat from './components/pages/Chat/Chat';
import Main from './components/pages/Main/Main';
import Login from './components/pages/Auth/Login/login'
import Signup from './components/pages/Auth/Signup/signup';
import ProtectedRoute from './ProtectedRoute';
import "./index.scss";


function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/login" element={<Login />}/>
        <Route exact path="/signup" element={<Signup />}/>
        <Route exact path="/" element={<ProtectedRoute element={<Main />} />}/>
        <Route path="/chat/:id" element={<ProtectedRoute element={<Chat />} />}/>

      </Routes>
    </Router>
  );
}

export default App;
